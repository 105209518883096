import Http from './http'

// 1.获取商品列表，可传: seriesid, sex(0 全部  1无2男3女), type（0 全部  1预订），ordertype（0 综合  1销量）page
export function getCommodityList(params) {
    return Http({
        url: '/home/getCommodityList',
        method: 'get',
        params
    })
}

// 2.获取商品详情
export function getCommodityDetail(params) {
    return Http({
        url: '/home/getCommodityDetail',
        method: 'get',
        params
    })
}
