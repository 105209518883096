import Http from './http'

// 1.编辑用户信息必传: username可传: sex（0保密 1男2女），birthday，frames=1（必传） files 头像图片
export function editUserAccount(data) {
    return Http({
        url: '/userinfo/editUserAccount',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
            'type': 'upfile'
        },
        data,
        transformRequest: [function(data) {
            const formData = new FormData()
            for (const key of Object.keys(data)) {
                formData.append(key, data[key])
            }
            return formData
        }]
    })
}
// 2.获取用户地址列表
export function getAddressList() {
    return Http({
        url: '/userinfo/getAddressList',
        method: 'get'
    })
}
// 3.获取省份
export function getProvince() {
    return Http({
        url: '/userinfo/getProvince',
        method: 'get'
    })
}
// 4.获取城市,必传: provincecode
export function getCity(params) {
    return Http({
        url: '/userinfo/getCity',
        method: 'get',
        params
    })
}
// 5.获取城区县,必传: citycode
export function getArea(params) {
    return Http({
        url: '/userinfo/getArea',
        method: 'get',
        params
    })
}
// 6.用户添加收货地址,必传: provincecode（省或市），citycode（市或区），areacode（区或县），name，mobile，address
export function addaddress(params) {
    return Http({
        url: '/userinfo/addaddress',
        method: 'get',
        params
    })
}
// 7.用户编辑收货地址,必传: user_address_id，provincecode（省或市），citycode（市或区），areacode（区或县），name，mobile，address
export function editaddress(params) {
    return Http({
        url: '/userinfo/editaddress',
        method: 'get',
        params
    })
}
// 8.用户设为默认收货地址,必传: user_address_id
export function setdefault(params) {
    return Http({
        url: '/userinfo/setdefault',
        method: 'get',
        params
    })
}
// 9.用户删除收货地址,必传: user_address_id
export function deladdress(params) {
    return Http({
        url: '/userinfo/deladdress',
        method: 'get',
        params
    })
}
// 10.获取用户订单列表，可传: status (0全部 1预订 2待付款 3待发货 4已完成 5已取消)
export function myOrderList(params) {
    return Http({
        url: '/userinfo/myOrderList',
        method: 'get',
        params
    })
}

// 11.用户个人中心 关注列表，可传: page
export function userCollection(params) {
    return Http({
        url: '/userinfo/userCollection',
        method: 'get',
        params
    })
}

// 12.用户K点列表
export function integralList(params) {
    return Http({
        url: '/userinfo/integralList',
        method: 'get',
        params
    })
}
// 13.用户昵称查重 ?username=haa
export function getUsernameTrue(params) {
    return Http({
        url: 'userinfo/getUsernameTrue',
        method: 'get',
        params
    })
}
// 14用户我的消息列表
export function newsList(params) {
    return Http({
        url: 'userinfo/newsList',
        method: 'get',
        params
    })
}
// 15.获取用户订单详情，必传: orderid
export function myOrderDetail(params) {
    return Http({
        url: '/userinfo/myOrderDetail',
        method: 'get',
        params
    })
}
// 16.取消订单，必传: orderid
export function cancelOrder(params) {
    return Http({
        url: '/userinfo/cancelOrder',
        method: 'get',
        params
    })
}
// 17.获取用户地址列表（去重）
export function getRemovalAddressList() {
    return Http({
        url: '/userinfo/getRemovalAddressList',
        method: 'get'
    })
}
// 18.查询快递信息/userinfo/getKuaidiInfo?fareorder=361454854591
export function getKuaidiInfo(params) {
    return Http({
        url: '/userinfo/getKuaidiInfo',
        method: 'get',
        params
    })
}

// 19.首页你可能喜欢
export function getLikeCommodity(params) {
    return Http({
        url: '/userinfo/getLikeCommodity',
        method: 'get',
        params
    })
}
