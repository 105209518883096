import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import breadcrumbs from '@c/breadcrumbs.vue'
import pageStep from '@c/pageStep.vue'
import sideImgList from '@c/sideImgList.vue'
import sideCommodity from '@c/sideCommodity.vue'
import sideInformation from '@c/sideInformation.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getCommodityDetail } from '@api/product'
import { getRemovalAddressList  } from '@api/my'
import { getFare } from '@api/order'
import { Affix } from 'vue-affix';
export default {
    name: 'ProductDetail',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        pageFooter,
        breadcrumbs,
        pageStep,
        sideImgList,
        sideCommodity,
        sideInformation,
        Affix
    },
    computed: {
        // type   1现货 2预订
        productType() {
            return this.curCommodityDetail ? ['现货', '预订'][ Number(this.curCommodityDetail.type) - 1 ] : '未知'
        },
        // 是否显示截止时间
        showAppointmenttime() {
            if(this.curCommodityDetail) {
                const appointmenttime = this.curCommodityDetail.appointmenttime
                // 大于当前时间才显示
                return appointmenttime !== '0000-00-00 00:00:00' && new Date(appointmenttime).getTime() > new Date().getTime()
            } else {
                return false
            };
        },
        // 是否显示截止时间
        showEndtime() {
            if(this.curCommodityDetail) {
                const endtime = this.curCommodityDetail.endtime
                // console.log('this.leftTime', this.leftTime, this.leftTime/1000/60/60)
                // 100小时内才显示
                return endtime !== '0000-00-00 00:00:00' && this.leftTime/1000/60/60 < 100
            } else {
                return false
            };
        },
        // 可购买最大值
        quotaMax() {
            console.log('this.curCommodityDetail.quota', this.curCommodityDetail.quota === '0')
            return this.curCommodityDetail && this.curCommodityDetail.quota
            ? this.curCommodityDetail.quota === '0' 
            ? Infinity
            : +this.curCommodityDetail.quota
            : 200
        }
    },
    data() {
        return {
            num: 1,
            stepData: [
                { up: '2019年10月18日', down: '预订开始，拍下定金', active: true },
                { up: '2019年10月20日', down: '商品出荷，等待补款通知', active: false },
                { up: '2019年10月22日', down: '补款出货，请填写收货地址', active: false }
            ],
            detail: {},
            commodity: {},
            commodityDetail: [],
            curCommodityDetail: null,
            contentList: [],
            prodContent: '', // 商品详情
            commodityid: '',
            commodityDetailsId: '',
            // productType: '',
            appointmenttime: '',
            supplementdate: '',
            supplementenddate: '',
            leftTime: '', // 限制中的剩余截止时间
            leftTimeTxt: '', // 截止时间显示的文字
            showAddress: '',
            fareMoney: '',
            addressList: [],
            timer: null,
            currentAddress: '',
            showBuyBtn: true,
            showBuyFixbar: false
        }
    },
    created() {
        this.getAddress()
        this.getAccount()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            //获取滚动时的高度
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // let height = this.$refs.scrollOne.getBoundingClientRect().top
            // console.log('scrollTop', scrollTop)
            if (scrollTop > 500) {
                //这是滑动到scrollTwo的距离要做的操作
                this.showBuyFixbar = true
            } else {
                this.showBuyFixbar = false
            }
        },
        // 切换规格
        switchType(typeObj) {
            this.commodityDetailsId = typeObj.commodity_details_id
            const obj = this.commodityDetail.find(item => item.commodity_details_id === this.commodityDetailsId)
            if(obj) this.curCommodityDetail = Object.assign({}, this.curCommodityDetail, obj)
            console.log('this.curCommodityDetail', this.curCommodityDetail)
            this.getCurCommodityDetailRelate()
        },
        // 获取用户地址
        async getAddress() {
            // 默认上海
            // this.showAddress = '上海市'
            // this.fareMoney = '6.00'
            const res = await getRemovalAddressList()
            console.log('res', res)
            if(res && res.detail) {
                this.addressList = res.detail
                if (this.addressList.length > 0) {
                    const defaultAddress = this.addressList.find(item => item.isdefault === '1') || this.addressList[0]
                    if(defaultAddress) {
                        this.currentAddress = defaultAddress.user_address_id
                        this.getFare(this.currentAddress)
                    }
                }
            } else { // 未登录情况下取fare

            }
        },
        // 获取运费
        async getFare(userAddressId) {
            console.log('userAddressId', userAddressId)
            if(!userAddressId) return
            const params = {
                users_address_id: userAddressId,
                commodity_details_id: this.commodityDetailsId
            }
            const res = await getFare(params)
            // console.log('res', res)
            if(res && res.detail && res.detail.faremoney) {
                this.fareMoney = res.detail.faremoney
            }
        },
        // handleChange(value) {
        //     console.log(value)
        // },
        handleLeftTime() {
            const self = this
            // 先清除上一次计时器
            if(this.timer) window.clearInterval(this.timer)
            this.timer = null
            self.leftTimeTxt = self.leftTime > 0 ? self.$utils.formatDuring(self.leftTime) : '已'
            this.timer = setInterval(() => {
                self.leftTime -= 1000
                self.leftTimeTxt = self.leftTime > 0 ? self.$utils.formatDuring(self.leftTime) : '已'
            }, 1000)
        },
        // 获取商品详情相关
        getCurCommodityDetailRelate() {
            // type   1现货 2预订
            // this.productType = this.curCommodityDetail ? ['现货', '预订'][ Number(this.curCommodityDetail.type) - 1 ] : '未知'
            if (this.curCommodityDetail) {
                this.commodityDetailsId = this.curCommodityDetail.commodity_details_id
                // 限制时间
                // if(this.curCommodityDetail.endtime !== '0000-00-00') {
                // this.curCommodityDetail.endtime = '2020-07-02'
                const endtime = this.curCommodityDetail.endtime + ' 23:59:59'
                this.leftTime = new Date(endtime).getTime() - new Date().getTime()
                this.handleLeftTime()
                // console.log('this.leftTime', this.leftTime)
                // }
                // 已地截单日期不能购买
                console.log('endtime', endtime)
                if(new Date().getTime() > new Date(endtime) || this.curCommodityDetail.num == 0) {
                    console.log('已过截单日期')
                    this.showBuyBtn = false
                } else {
                    this.showBuyBtn = true
                }
                // 预订
                this.appointmenttime = this.curCommodityDetail ? this.curCommodityDetail.appointmenttime : ''
                // 补款
                this.supplementdate = this.curCommodityDetail ? this.curCommodityDetail.supplementdate : ''
                // 补款截止
                // console.log('curCommodityDetail', this.curCommodityDetail)
                this.supplementenddate = this.curCommodityDetail ? this.curCommodityDetail.supplementenddate : ''
                this.stepData[0].up = parseInt(this.appointmenttime) === 0 ? '未定' : new Date(this.appointmenttime).Format("yyyy年MM月dd日")
                this.stepData[1].up = this.$utils.getPublishString(this.commodity.publishtype, this.commodity.publishyear, this.commodity.publishtime)
                this.stepData[2].up = parseInt(this.supplementenddate) === 0 ? '未定' : this.curCommodityDetail.isopen !== '1' ? '暂未开启' : new Date(this.supplementenddate).Format("yyyy年MM月dd日")
            }
        },
        // 获取详情
        async getData(commodityid, commodity_details_id) {
            const res = await getCommodityDetail({
                commodityid
            })
            if (res && res.list) {
                this.detail = Object.assign({}, this.detail, res.list)
                this.commodity = Object.assign({}, this.commodity, this.detail.commodity)
                this.commodityDetail = this.detail.commodity_detail
                this.prodContent = this.detail.commodity.content
                this.contentList = this.detail.information.list
                // 先取未登录的运费
                this.fareMoney = this.detail.fare
                this.curCommodityDetail = this.commodityDetail.find(item => item.commodity_details_id === commodity_details_id) || this.commodityDetail[0]
                console.log('this.curCommodityDetail', this.curCommodityDetail)
                this.getCurCommodityDetailRelate()
            } else {
                this.$router.push('/product')
                this.$message({
                    type: 'warning',
                    message: '商品下架 ，请选择其他商品',
                    duration: 1000,
                });
            }
            // console.log('product detail', this.detail)
        },
        checkBuyProd() {
            // 判断是否存在当前选中的商品
            console.log('this.num', this.num)
            const curCommodityDetail = this.curCommodityDetail
            console.log('curCommodityDetail', curCommodityDetail)
            if (!curCommodityDetail || !curCommodityDetail.commodity_details_id) {
                this.$notify({ title: '提示', type: 'warning', message: '请选择商品', position: 'bottom-right' })
                return false
            } else if(!this.num || this.num <= 0) {
                this.$notify({ title: '提示', type: 'warning', message: '商品数量要大于0，请检查商品数量', position: 'bottom-right' })
                return false
            } else {
                return true
            }
        },
        doBuy() {
            // 购买的商品校验
            if(!this.showBuyBtn) return
            if(!this.checkBuyProd()) return
            const buyList = [{ 'commodity_details_id': this.curCommodityDetail.commodity_details_id, 'num': this.num }]
            console.log('buyList', buyList)
            // 纯前端判断是否登录
            if (this.$utils.getUser()) {
                console.log('this.$utils.getUser()', this.$utils.getUser())
                this.$router.push(
                    {
                        path: '/order', query: { 'buy-data': JSON.stringify(buyList) }
                    }
                )
            } else {
                // 跳转登录页
                this.$router.push(
                    {
                        path: '/login', query: { 'redirectUrl': `/order?buy-data=${JSON.stringify(buyList)}` }
                    }
                )
            }
        },
        scrollTop() {            
            const appDom = document.querySelector('html')
            if(appDom) appDom.scrollTo(0, 60)
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(to, from)
        next(vm => {
            // console.log(vm.$route.query)
            vm.commodityid = vm.$route.query.commodityid
            vm.commodityDetailsId = vm.$route.query.commodity_details_id
            if (vm.commodityid) {
                vm.getData(vm.commodityid, vm.commodityDetailsId)
            }
        })
    },
    beforeDestroy() {
        if(this.timer) window.clearInterval(this.timer)
    },
    beforeRouteLeave(to, from, next) {
        if(this.timer) window.clearInterval(this.timer)
        next()
    }
}
